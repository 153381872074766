<template>
  <div class="lg-main">
    <v-row>
      <v-col cols="12" md="8" class="d-none d-md-block">
        <div class="back-img"></div>
      </v-col>
      <v-col cols="12" md="4" class="login">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-img
              lazy-src="../assets/logo.png"
              max-height="150"
              max-width="150"
              src="../assets/logo.png"
            ></v-img>
          </v-col>
          <v-col cols="12" class="justify-center text-center">
            <h1 cols="12" class="blue-grey--text font-weight-black">IMSO</h1>
            <span class="blue-grey--text font-weight-light">
              Information Management System of OPEnE
            </span>
          </v-col>
          <v-col cols="12" class="d-flex justify-center font-weight-light">
            <span class="grey--text">
              Please enter your email and password
            </span>
          </v-col>
          <v-col cols="12" class="d-flex justify-center py-0">
            <v-text-field
              v-model="email"
              :rules="[rules.required, rules.email]"
              label="E-mail"
              dense
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex justify-center py-0">
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="show ? 'text' : 'password'"
              name="input-10-1"
              label="Password"
              :error-messages="emailMatch"
              @click:append="show = !show"
              v-on:keyup.enter="login"
              dense
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between py-0 my-0">
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="red lighten-2" dark v-bind="attrs" v-on="on">
                  Forgot Password?
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 indigo lighten-5 primary--text">
                  How to change or reset your password?
                </v-card-title>

                <v-card-text class="mt-5">
                  <h4>Step 01</h4>
                  <p>Click <span class="font-weight-black">LOGIN WITH GOOGLE </span> <v-icon color="#0277BD" style="background-color:#E1F5FE; border-radius:50%; padding:5px" >mdi-google</v-icon> </p>
                  <h4>Step 02</h4>
                  <p>Login to the system using your registered gmail address <v-icon color="#00695C" style="background-color:#E0F2F1; border-radius:50%; padding:5px">mdi-login</v-icon> </p>
                  <h4>Step 03</h4>
                  <p>At the top, tap <v-icon color="#6D4C41" style="background-color:#EFEBE9; border-radius:50%; padding:5px">mdi-dots-vertical</v-icon> then select <span class="font-weight-black">Settings</span> and enter your new password twice to confirm, then select <span class="font-weight-black">Save</span> <v-icon color="#6D4C41" style="background-color:#EFEBE9; border-radius:50%; padding:5px">mdi-cog</v-icon> </p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn
              class="m-0"
              :loading="login_loading"
              :disabled="login_loading"
              color="primary"
              @click="login"
            >
              Login
            </v-btn>
          </v-col>
          <v-divider></v-divider>

          <v-col
            cols="12"
            class="
              d-flex
              justify-center
              text-center
              pb-0
              font-weight-light
              pt-5
            "
          >
            <span class="grey--text"> or use google auth </span>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              block
              :loading="gmail_loading"
              :disabled="gmail_loading"
              color="primary"
              @click="
                clear();
                loginGoogle();
              "
            >
              Login with Google <v-icon right dark> mdi-google </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const axios = require("axios").default;
export default {
  data() {
    return {
      dialog: false,
      snackbar: false,
      text: ``,
      timeout: 3000,
      email: "",
      show: false,
      password: "",
      loader: null,
      login_loading: false,
      gmail_loading: false,
      emailMatch: null,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    clear() {
      this.rules = "";
      this.email = "";
      this.password = "";
    },
    login() {
      if (this.email && this.password) {
        const l = "login_loading"; // button loader
        this[l] = !this[l]; // button loader

        axios
          .post(this.$backend_url+"login", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            this[l] = false; // button loader
            if (response.data.success) {
              localStorage.imso_user = JSON.stringify(response.data);
              this.$router.go();
            } else {
              this.emailMatch =
                "The email and password you entered don't match";
              this.snackbar = true;
              this.text = `The email address and the password you've entered don't match. Please retry.`;
            }
          })
          .catch((res) => {
            this[l] = false;
            this.snackbar = true;
            if (res.response.status == 401) {
              this.text = `The email address and the password you've entered don't match. Please retry.`;
              console.log("Errors:", res);
            }
            if (res.response.status == 500) {
              this.text = `Internal Server Error`;
              console.log("Errors:", res);
            }
            if (res.response.status == 400) {
              this.text = `Bad HTTP Request`;
              console.log("Errors:", res);
            }
          });
      } else {
        this.snackbar = true;
        this.text = `Enter email and password`;
      }
    },
    async loginGoogle() {
      try {
        if (this.$gAuth.isInit) {
          const l = "gmail_loading"; // button loader
          this[l] = !this[l]; // button loader
          const googleUser = await this.$gAuth.signIn();
          if (this.$gAuth.isAuthorized) {
            axios
              .post(this.$backend_url+"login-google", {
                email: googleUser.getBasicProfile().getEmail(),
                profile: googleUser.getBasicProfile().getImageUrl(),
                id: googleUser.getId(),
              })
              .then((response) => {
                this[l] = false; // button loader
                if (response.data["success"]) {
                  localStorage.imso_user = JSON.stringify(response.data);
                  this.$router.go();
                } else {
                  this.emailMatch =
                    "The email and password you entered don't match";
                }
              })
              .catch((res) => {
                this[l] = false;
                this.snackbar = true;
                if (res.response.status == 401) {
                  this.text = `Gmail you've choosen is not associated with IMSO`;
                  console.log("Errors:", res);
                }
                if (res.response.status == 500) {
                  this.text = `Internal Server Error`;
                  console.log("Errors:", res);
                }
                if (res.response.status == 400) {
                  this.text = `Bad HTTP Request`;
                  console.log("Errors:", res);
                }
              });
          } else {
            this[l] = false;
            this.snackbar = true;
            this.text = `Something went wrong`;
          }
        } else {
          this.snackbar = true;
          this.text = `No Internet`;
        }
      } catch (error) {
        this.gmail_loading = false;
        window.location.reload();
      }
    },
  },
  mounted() {
    if (localStorage.imso_user) {
      this.$router.push("/");
    }
  },
};
</script>

<style>
.lg-main {
  top: 0;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  z-index: 99 !important;
  background-color: #ffffff;
}

.back-img {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-image: url("../assets/back.png");
}

.login {
  display: flex;
  justify-content: center;
  padding: 50px;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.login::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>